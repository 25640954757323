.button {
  background-color: #226125;
  border: none;
  padding: .5rem 2rem;
  border-radius: 20px;
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  cursor: pointer;
  z-index: 100;
  display: flex;
  gap: 6px;
  align-items: center;
}
.button:hover {
   background-color: rgb(48, 95, 80);
}
.button-text {
  margin: 0 .4rem;
}
.detailModal-info {
  height: 300px;
  overflow: auto;
}
.detailModal-info-full {
  height: 550px;
  overflow: auto;
}
.detailModal-info::-webkit-scrollbar {
  width: 10px;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 10px;
  padding: 0 1px;
}
.detailModal-info::-webkit-scrollbar-thumb {
  background-color: #d1d1d1; 
  border-radius: 17px;
  margin: 0 8px;
} 
.detailModal-info-full::-webkit-scrollbar {
  width: 10px;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 10px;
  padding: 0 1px;
}
.detailModal-info-full::-webkit-scrollbar-thumb {
  background-color: #d1d1d1; 
  border-radius: 17px;
  margin: 0 8px;
} 