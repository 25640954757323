.results-layout {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 1.5rem;
}
.results-container {
  max-width: 1600px;
  margin: 0 auto;
}
.results-container li {
  list-style: none;
}
.results__title {
  margin-bottom: 2rem;
}
.results__status {
  display: flex;
  margin: 1rem 0 1rem 0;
  justify-content: space-between;
}
.results__status-cards {
  display: flex;
  align-items: center;
  /* min-width: 200px; */
  gap:.8rem;
  /* margin-right: 1rem; */
  border-radius: 10px;
  padding: .8rem 1rem;
  background-color: rgb(243, 243, 243);
  border: none;
  cursor: pointer;
  width: 200px;
}

.results__status-cards:focus {
  background-color: rgb(242, 255, 0);
}
.result__status-buttonAll {
  display: flex;
  align-items: center;
  gap:.8rem;
  border-radius: 10px;
  padding: .8rem 1rem;
  background-color: rgb(243, 243, 243);
  border: none;
  cursor: pointer;
  width: 200px;
  background-color: rgb(242, 255, 0);
}
.result__status-buttonAll strong {
background-color: rgb(167, 167, 167);
 padding: .7rem;
 border-radius: 100px;
 height: 2.5rem;
 width: 2.5rem;
 color:aliceblue;
 text-align: center;

}

.results__status-cards strong {
 background-color: rgb(167, 167, 167);
 padding: .7rem;
 border-radius: 100px;
 height: 2.5rem;
 width: 2.5rem;
 color:aliceblue;
 text-align: center;

}
.results__status-cards strong:focus {
  background-color: rgb(215, 24, 91);
}

.pediente-card {
  background-color: rgb(242, 255, 0);
}
.programados-card {
  background-color: rgb(37, 135, 247);
  color: white;
}
.results__OrderContainer {
  height: 62vh;
  margin-top: 2rem;
}
.results__order-header {
 display: flex;
justify-content: space-between;
 border-bottom: 2px dashed rgb(206, 206, 206);
 padding: 2rem 0;
 align-items: center;
}
.results__order-header .order-item {
  text-align: left;
}
.results__order {
 display: flex;
 /* gap: 10rem; */
 justify-content: space-between;
 border-bottom: 2px dashed rgb(206, 206, 206);
 padding: 2rem 0;
 align-items: center;
 /* justify-content: space-around; */
}
.results__order .order-item {
 line-height: 1.5rem;
}
.results__order .order-item strong {
 font-size: 1.3rem;
}
.results__order .order-item p {
  font-weight: bold;
  color:  rgb(186, 186, 186);
}
.order-button {
  border: none;
  padding: .5rem 2.5rem;
  background-color: rgb(255, 0, 98);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: white;
  font-size: 1.2rem;
  border-radius: 12px;
  cursor: pointer;
}
.order-button:hover {
  background-color: rgb(167, 97, 124);
}
.order-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rts___tabs {
  padding-top: 30px
}

.rts___nav___btn {
  padding: 0 10px;
  padding-top: 20px;
}

.rts___nav___btn svg {
  max-width: unset;
}

.rts___nav___btn:hover {
  background-color: unset;
}

.rts___nav___btn:hover > svg {
  stroke: unset;
}

.rts___btn {
  border-radius: unset;
  border: none;
}