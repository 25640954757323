.customizationList-box {
  display: flex;
  flex-direction: column;
}
.customizationList-box button {
  list-style: none;
  font-family: 'Roboto', sans-serif;
  margin-bottom: .7rem;
  background-color: rgb(248, 248, 248);
  padding: .5rem .7rem;
  border-radius: .3rem;
  cursor: pointer;
  border: none;
  text-align: left;
}
.buttonClassFocus {
   background-color: rgb(213, 246, 210);

}
.customizationList-box button:hover {
  background-color: rgb(213, 246, 210);
  transition: 1s;
}
.customizationList-box button:focus {
  background-color: rgb(213, 246, 210);
}
.customizationList-box button p {
  font-size: 12px;
  color:  rgb(136, 136, 136);
  margin-top: .3rem;
}