.login-view {
  width: 100vw;
  height: 80vh;
  display: flex;
  align-items: center;
}

.container-code {
  display: flex;
  justify-content: space-evenly;
}

.input-code {
  /* margin: 0 .5rem 0 .5rem; */
  height: 3rem;
  width: 4.7rem;
  background: #f3f7fa;
  border-radius: 15px;
  border: none;
  text-align: center;
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #8c9baf;
}

.input-code:focus {
  border: 2px solid #009739;
}
