.results__order {
 display: flex;
 /* gap: 10rem; */
 justify-content: space-between;
 border-bottom: 2px dashed rgb(206, 206, 206);
 padding: 2rem 0;
 align-items: center;
 /* justify-content: space-around; */
}
.results__order .order-item {
 line-height: 1.5rem;
}
.results__order .order-item strong {
 font-size: 1.3rem;
}
.results__order .order-item p {
  font-weight: bold;
  color:  rgb(186, 186, 186);
}
.order-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}