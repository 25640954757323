.WarningModal {
    background-color: rgba(46, 44, 54, 0.2);
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 3;
    top: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.content{
    border: 1px solid #e6ebf1;
    border-radius: 8px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    animation: showPopUp 0.5s;
    position: relative;
}

@media screen and (min-width: 1280px) {
    .WarningModal {
        right: -150px;
    }
}