.imageUpload-conatainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
}
.imageUpload-conatainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.imageUpload__title {
  color: rgb(169, 169, 169);
}
.imageUpload__imagePreview-horizontal {
  background-color: #d4edda;
  border: 2px solid rgb(49, 152, 29);
  width: 300px;
  height: 270px;
  border-radius: 10px;
    transition: all 1s;
    overflow: hidden;
}
.imageUpload__imagePreview-vertical {
  background-color: #d4edda;
  border: 2px solid rgb(49, 152, 29);
  width: 190px;
  height: 300px;
  border-radius: 10px;
  transition: all 1s;
   overflow: hidden;
}
.imageUpload__buttons {
  /* padding: 2rem; */
  display: flex;
  gap: 2rem;
}
.imageUpload__button-item {
  border: none;
  padding: .7rem 2rem;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}
.buttom-cancel {
  background-color: rgb(61, 140, 251);
  border: 2px solid  rgb(61, 140, 251);
  color: rgb(255, 255, 255);
  -webkit-box-shadow: 2px 4px 6px 0px rgba(189,189,189,1);
  -moz-box-shadow: 2px 4px 6px 0px rgba(189,189,189,1);
  box-shadow: 2px 4px 6px 0px rgba(189,189,189,1);
}
.buttom-confirm {
  background-color: rgb(49, 152, 29);
  border: 2px solid  rgb(49, 152, 29);
  color: white;
  -webkit-box-shadow: 2px 4px 6px 0px rgba(189,189,189,1);
  -moz-box-shadow: 2px 4px 6px 0px rgba(189,189,189,1);
  box-shadow: 2px 4px 6px 0px rgba(189,189,189,1);
}
.imageUpload__icon {
  fill: #7d7d7d;
  font-size: 1rem;
  cursor: pointer;
}