.toolbarManagers {
  background-color: rgb(237, 237, 237);
  /* height: 80px; */
  padding: 1.5rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.toolbarManagers__container {
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.toolbarManagers__infoOne {
  display: flex;
  /* justify-content: space-between; */
  width: 30%;
  height: 60px;
  gap: 1rem;
}
.toolbarManagers__infoOne .toolbarManagers__infoOne-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.toolbarManagers__infoOne .toolbarManagers__infoOne-avatar {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  color: white;
  /* width: 200px; */
  /* height: 80px; */
  overflow: hidden;
}
.toolbarManagers__infoOne .toolbarManagers__infoOne-pedidos {
  background-color: rgb(67, 169, 46);
  padding: 1rem;
  border-radius: 10px;
  color: white;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .7rem;
}
.toolbarManagers__infoOne-pedidos strong {
  background-color: rgb(224, 51, 109);
  padding: .7rem;
  border-radius: 100px;
  height: 2.5rem;
  width: 2.5rem;
  text-align: center;
}
.toolbarManagers__infoOne .toolbarManagers__infoOne-productos {
  background-color: rgb(124, 124, 124);
  padding: 1rem;
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: center;
   align-items: center;
}